<template>
  <type-based-transition :transitionType="'fadeUpDown'">
    <div v-if="isComplete" id="complete" class="form-complete">
      <h1>{{ title }}</h1>
      <p>{{ subtitle }}</p>
      <!--<p :key="key" v-for="(item, key, index) in $store.state.lead.formData">
        <span v-colorswatch:color="index"> {{ key }}: </span> {{ item }}
      </p>-->
    </div>
  </type-based-transition>
</template>
<script>
import TypeBasedTransition from "../Transitions/TypeBasedTransition.vue"

export default {
  name: "form-result",
  inject: ["formState"],
  components: {
    TypeBasedTransition,
  },
  computed: {
    title() {
      return this.$store.state.lead.formConfig.result.title
    },
    subtitle() {
      return this.$store.state.lead.formConfig.result.subtitle
    },
    isComplete() {
      return this.formState.isComplete
    },
  },
  //watch: {
  //  isComplete() {
  //    //console.log(this.selectedType);
  //    if (this.isComplete) {
  //      this.$store.dispatch("lead/createTestimony")
  //    }
  //  },
  //},
}
</script>
