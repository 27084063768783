<script>
import i18n from "@/i18n"
import _ from "lodash"
import { getComponent } from "./ComponentTypes"
import formConfigEN from "../config/en/formConfig_en.json"

export default {
  name: "form-config-provider",
  functional: true,
  render(createElement, ctx) {
    const store = ctx.parent.$store
    store.commit("lead/formConfig")
    const locale = i18n.locale
    //const selectedType = store.state.lead.selectedType
    let formConfig = 0

    if (locale === "en") {
      formConfig = _.find(formConfigEN.forms, ["key", 1]).fields
    } else if (locale === "es-MX") {
      formConfig = _.find(formConfigEN.forms, ["key", 1]).fields
    }

    let fieldObjects = formConfig.map(field => {
      let component = getComponent(field.type)
      return { ...field, component }
    })

    return createElement(getComponent("formTemplate"), {
      props: {
        formFields: fieldObjects,
      },
    })
  },
}
</script>
