<template>
  <date-picker
    :type="type"
    v-model="selectedDate"
    @input="$emit('input', selectedDate)"
    valueType="format"
  ></date-picker>
</template>
<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
export default {
  props: ["type"],
  components: { DatePicker },
  data() {
    return {
      selectedDate: null,
    }
  },
}
</script>
