<template>
  <div>
    <label v-for="(option, key) in options" :key="key">
      <input
        type="checkbox"
        :value="option"
        :id="option"
        v-model="selectedValues"
        v-checked:[option]="selectedValues"
      />
      <template>{{ option }}</template>
      <!--<template v-if="option === other">
        {{ option }}
        <input
          type="text"
          :disabled="!selectedValues.includes(other)"
          :placeholder="placeholder"
          :hidden="!selectedValues.includes(other)"
        />
      </template>-->
    </label>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  props: {
    options: {
      required: true,
    },
    value: {
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      selectedValues: [],
      other: null,
      placeholder: null,
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
  },
  watch: {
    selectedValues(newVal) {
      this.$emit("input", newVal)
    },
  },
  created() {
    if (this.value.length > 0) this.selectedValues = this.value
  },
  mounted() {
    this.other = this.lang === "en" ? "Other" : "Otro"
    this.placeholder =
      this.lang === "en"
        ? "Type your answer here..."
        : "Escriba su respuesta aquí..."
  },
  directives: {
    checked(el, binding) {
      return binding.value.includes(binding.arg)
        ? (el.checked = true)
        : (el.checked = false)
    },
  },
}
</script>
<style>
label {
  display: block;
}
</style>
