<template>
  <div class="upload">
    <div class="table-responsive d-flex">
      <div class="btn-group">
        <file-upload
          class="btn btn-default dropdown-toggle"
          :accept="accept"
          :extensions="extensions"
          :size="size || 0"
          :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
          :headers="headers"
          :data="data"
          :add-index="addIndex"
          name="mimes_field"
          v-model="files"
          @input-filter="inputFilter"
          @input-file="inputFile"
          ref="upload"
        >
          Seleccionar
        </file-upload>
      </div>
      <div class="ml-4" v-if="files.length === 0"> No hay archivo </div>
      <div class="ml-4 d-flex centered" v-for="file in files" :key="file.id">
        <span class="filename">
          {{ file.name }}
        </span>
        <span class="ml-4">
          <a
            class="dropdown-item remove-file"
            href="#"
            @click.prevent="$refs.upload.remove(file)"
            > x
          </a>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import ImageCompressor from "@xkeshi/image-compressor"
import FileUpload from "vue-upload-component"
import { mapMutations } from "vuex"

export default {
  props: ["type"],
  components: {
    FileUpload,
  },
  data() {
    return {
      files: [],
      accept: "image/png,image/gif,image/jpeg,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv",
      extensions: "gif,jpg,jpeg,png,doc,docx",
      // extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
      // extensions: /\.(gif|jpe?g|png|webp)$/i,
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: false,
      dropDirectory: false,
      addIndex: false,
      thread: 3,
      name: "file",
      headers: {
        "X-Csrf-Token": "xxxx",
      },
      data: {
        _csrf_token: "xxxxxx",
      },
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      addData: {
        show: false,
        name: "",
        type: "",
        content: "",
      },
      editFile: {
        show: false,
        name: "",
      },
    }
  },
  watch: {
    "addData.show"(show) {
      if (show) {
        this.addData.name = ""
        this.addData.type = ""
        this.addData.content = ""
      }
    },
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
        // Automatic compression
        if (
          newFile.file &&
          newFile.type.substr(0, 6) === "image/" &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = "compressing"
          const imageCompressor = new ImageCompressor(null, {
            convertSize: Infinity,
            maxWidth: 512,
            maxHeight: 512,
          })
          imageCompressor
            .compress(newFile.file)
            .then(file => {
              this.$refs.upload.update(newFile, {
                error: "",
                file,
                size: file.size,
                type: file.type,
              })
            })
            .catch(err => {
              this.$refs.upload.update(newFile, {
                error: err.message || "compress",
              })
            })
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = ""
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
        // Thumbnails
        newFile.thumb = ""
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob
        }
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.upload.update(newFile, { error: "size" })
          }
        }
      }
      // Automatically activate upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true
        }
      }
      this.updateField({ key: "document", value: newFile })
    },
    alert(message) {
      alert(message)
    },
    onEditFileShow(file) {
      this.editFile = { ...file, show: true }
      this.$refs.upload.update(file, { error: "edit" })
    },
    onEditorFile() {
      if (!this.$refs.upload.features.html5) {
        this.alert("Your browser does not support")
        this.editFile.show = false
        return
      }
      let data = {
        name: this.editFile.name,
      }
      if (this.editFile.cropper) {
        let binStr = atob(
          this.editFile.cropper
            .getCroppedCanvas()
            .toDataURL(this.editFile.type)
            .split(",")[1]
        )
        let arr = new Uint8Array(binStr.length)
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i)
        }
        data.file = new File([arr], data.name, { type: this.editFile.type })
        data.size = data.file.size
      }
      this.$refs.upload.update(this.editFile.id, data)
      this.editFile.error = ""
      this.editFile.show = false
    },
    onAddData() {
      this.addData.show = false
      if (!this.$refs.upload.features.html5) {
        this.alert("Your browser does not support")
        return
      }
      let file = new window.File([this.addData.content], this.addData.name, {
        type: this.addData.type,
      })
      this.$refs.upload.add(file)
    },
    ...mapMutations({
      updateField: "lead/updateField",
    }),
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
          
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      
      return new File([u8arr], filename, {type:mime});
    }
  },
  mounted() {
    if (this.$store.state.documentToEdit !== "") {
      //let fileURL = `data:${this.$store.state.documentToEdit.datatype};base64,${this.$store.state.documentToEdit.document}`;
      //let fileToEdit = this.dataURLtoFile(fileURL, this.$store.state.documentToEdit.title);
      this.files.push(this.$store.state.lead.formData.document.file);
      // Changign formData for validation on edit upload
      //this.$store.state.lead.formData.document = { file: fileToEdit };
    }
  }
}
</script>
<style lang="scss">
.upload {
  .btn-group {
    width: 150px;
    .file-uploads {
      width: 100%;
      font-size: inherit;
      padding-left: 10px;
      align-items: center;
      border: 1px solid #ddd;
      color: #ddd;
    }
  }
  .centered {
    align-items: center;
  }
  .remove-file {
    color: white;
    background-color: inherit;
    font-weight: bold;
  }
  .remove-file:focus {
    background-color: inherit;
  }
  .remove-file:hover {
    background: inherit;
  }
}
</style>