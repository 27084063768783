<template>
  <div class="form-error-message">
    <type-based-transition transition-type="fadeUpDown">
      <button
        class="form-button"
        v-if="style === 'typeform' && formState.isValid"
      >
        OK
      </button>
      <span v-if="!formState.isValid">
        <slot />
      </span>
    </type-based-transition>
  </div>
</template>
<script>
import TypeBasedTransition from "../Transitions/TypeBasedTransition.vue"

export default {
  name: "field-error",
  inject: ["formState"],
  components: {
    TypeBasedTransition,
  },
  computed: {
    style() {
      return this.$store.state.lead.formConfig.style
    },
  },
}
</script>
