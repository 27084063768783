<template>
  <div>
    <div v-if="style === 'typeform'">
      <form-progress />
      <div class="nav d-flex justify-content-end">
        <div class="form-button back" @click="$emit('back')">↑</div>
        <div class="form-button next" @click="$emit('next')">↓</div>
      </div>
    </div>
    <div v-if="style !== 'typeform'">
      <div class="nav d-flex justify-content-end">
        <div class="centered-button d-flex">
          <div class="form-button submit" v-bind:style="submitButton" @click="cancel()">Cancelar</div>
          <div class="form-button submit" v-bind:style="submitButton" @click="onClick">Guardar</div>
          <div class="form-error-message"  v-bind:style="styleError"> {{ errorMessage }} </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormProgress from "./FormProgress.vue"
export default {
  name: "form-nav",
  components: {
    "form-progress": FormProgress,
  },
  data() {
    return {
      errorMessage: "",
      styleError: {
        display: "none",
        opacity: 0
      },
      submitButton: {
        display: "block",
        opacity: 1
      }
    }
  },
  computed: {
    style() {
      return this.$store.state.lead.formConfig.style
    },
  },
  methods: {
    cancel() {
      this.$router.replace({ name: "documents" });
    },
    onClick() {
      if (this.$parent.$validator.errors.items.length > 0) {
        this.showErrorMessage("Favor de llenar los campos requeridos");
        return;
      } else if (!this.$store.state.lead.formData.document.file) {
        this.showErrorMessage("No se ha cargado ningun documento");
        return;
      }
      this.getBase64(this.$store.state.lead.formData.document.file);
      this.$parent.formState.activeField = 0
      for (
        let i = this.$parent.formState.activeField;
        i < this.$parent.formState.formLength;
        i++
      ) {
        this.$emit("next")
      }
    },
    showErrorMessage(message) {
      this.styleError = {
        display: "block",
        opacity: 1
      }
      this.submitButton = {
        display: "none",
        opacity: 0
      }
      this.errorMessage = message;
      this.hideErrorMessage();
    },
    hideErrorMessage() {
      setTimeout(() => {
        this.errorMessage = "";
        this.styleError = {
          display: "none",
          opacity: "0"
        }
        this.submitButton = {
          display: "block",
          opacity: 1
        }
      }, 3000)
    },
    getBase64(file) {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = async () => {
        var fileEncoded = reader.result;
        let docInfo = {
          url: fileEncoded.replace(/^data:.*;base64,/, ""),
          dataType: file.type,
          title: this.$store.state.lead.formData.title,
          subtitle: this.$store.state.lead.formData.subtitle,
          type: this.$store.state.lead.formData.type.typeId
            ? this.$store.state.lead.formData.type.typeId
            : this.$store.state.lead.formData.type,
          typeName: this.$store.state.lead.formData.type.typeText
            ? this.$store.state.lead.formData.type.typeText
            : this.$store.state.lead.formData.type,
          year: Number(this.$store.state.lead.formData.year),
          state: this.$store.state.lead.formData.state,
          color: this.$store.state.lead.formData.type.color
            ? this.$store.state.lead.formData.type.color
            : "",
        }

        try {
          let action = "saveDocument";
          if (this.$store.state.documentToEdit !== "") {
            action = "editDocument";
            docInfo.id = this.$store.state.documentToEdit.id;
          }
          let response = await this.$store.dispatch(action, docInfo);
          this.$router.replace({ name: "documents" });
          this.$store.commit("setNotification", {
            active: true,
            text: response.data,
            color: "green"
          });
        } catch (err) {
          this.$store.commit("setNotification", {
            active: true,
            text: "Hubo un error al guardar el documento",
            color: "firebrick",
          });
        }
      };

      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }
}
</script>
