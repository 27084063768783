<template>
  <v-select
    :type="type"
    v-model="selectedOption"
    @input="$emit('input', selectedOption)"
    :options="options"
  ></v-select>
</template>

<script>
export default {
  props: ["options", "type", "name"],
  data() {
    return {
      selectedOption: null,
    }
  },
  mounted() {
    /* Workaround, bad implementation
      * To do, develop a way to mantain equal json namings 
    */
    if (this.name === "state") {
      this.selectedOption = this.$store.state.documentToEdit.entity;
    } else if (this.name === "year") {
      this.selectedOption = this.$store.state.documentToEdit.year;
    }
    
  }
}
</script>
