<template>
  <div>
    <label v-for="(option, key) in options" :key="key">
      <input
        type="radio"
        :value="option"
        @input="$emit('input', $event.target.value)"
        :checked="searchMatchValue(option, value)"
      />
      <template>{{ option }}</template>
      <!--<template v-if="option === other">
        {{ option }}
        <input
          type="text"
          :disabled="value !== other"
          :placeholder="placeholder"
          :hidden="value !== other"
        />
      </template>-->
    </label>
  </div>
</template>
<script>
export default {
  props: ["options", "optionsInfo", "value"],
  data() {
    return {
      other: null,
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
  },
  methods: {
    searchMatchValue(option, value) {
      if (value) {
        // Workaround, check consistency on datatypes
        value = typeof(value) === "string" ? value : value.typeId
        let valueInfo = this.optionsInfo.filter(opt => {
          return opt.typeId === value
        })[0].typeText

        return option === valueInfo
      }

      return false;
    }
  },
  mounted() {
    this.other = this.lang === "en" ? "Other" : "Otro"
  },
}
</script>
