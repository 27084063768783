<template>
  <data-driven-transition :isNext="isNext">
    <div
      class="field-group"
      v-if="style === 'typeform' && active"
      :key="fieldId"
    >
      <slot />
    </div>
    <div class="field-group" v-if="style !== 'typeform'" :key="fieldId">
      <slot />
    </div>
  </data-driven-transition>
</template>
<script>
import DataDrivenTransition from "../Transitions/DataDrivenTransition.vue"

export default {
  name: "field-group",
  props: ["fieldId"],
  inject: ["formState"],
  components: {
    DataDrivenTransition,
  },
  computed: {
    style() {
      return this.$store.state.lead.formConfig.style
    },
    active() {
      return this.formState.activeField === this.fieldId
    },
    isNext() {
      return this.formState.isNext
    },
  },
}
</script>
